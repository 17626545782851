import React, { useEffect, useMemo, useState } from 'react'
import algoliasearch from 'algoliasearch/lite'
import {
  InstantSearch, NumericMenu, Panel, SearchBox
} from 'react-instantsearch-dom'

import styled from 'styled-components'
import {
  getCategoryById,
  recursiveCategoriesRenaming
} from '../../utils/categoriesUtils'
import CustomHierarchicalMenu from '../AlgoliaCustoms/HierarchicalMenu/CustomHierarchicalMenu'
import CustomMenu from '../AlgoliaCustoms/Menu/CustomMenu'
import {
  createURL,
  searchStateToUrl,
  urlToSearchState
} from '../../utils/urlUtils'
import Results from '../Results'
import CustomBreadcrumb from '../AlgoliaCustoms/Breadcrumb/CustomBreadcrumb'
import CustomSearch from '../AlgoliaCustoms/Search/CustomSearch'

const TIME_BEFORE_REFRESH_URL = 400
const INDEX_NAME = 'test-toolpad-recipes'

const HitsByCategories = ({
  search,
  pathname,
  hash,
  resultState,
  searchState: propSearchState,
  dailyHits,
  localFiles,
}) => {
  const searchClient = useMemo(
    () => algoliasearch('JKWQZTTE4U', 'e60f2bef9e1bc41e82de0d3a8fb5d74d'),
    []
  )
  const searchIndex = useMemo(() => searchClient.initIndex(INDEX_NAME), [])

  const [searchState, setSearchState] = useState(propSearchState)
  const [firstsDailyRecipes, setFirstsDailyRecipes] = useState(dailyHits || [])

  const [debouncedSetState, setDebouncedSetState] = useState()

  useEffect(() => {
    setSearchState(urlToSearchState(search, pathname, hash))
  }, [])

  const category =
    searchState.hierarchicalMenu &&
    searchState.hierarchicalMenu['categories_tree.lvl0']
      ? searchState.hierarchicalMenu['categories_tree.lvl0']
        .split(' > ')
        .slice(-1)[0]
      : null
  const pageTitle = `${getCategoryById(category).name || ''}${
    category ? ', l' : 'L'
  }es recettes par ChefClub`

  const onSearchStateChange = async (updatedSearchState) => {
    // Refresh URL
    // console.log('SearchState updated : ', updatedSearchState)
    clearTimeout(debouncedSetState)
    setDebouncedSetState(
      setTimeout(() => {
        window.history.pushState(
          updatedSearchState,
          null,
          searchStateToUrl(updatedSearchState)
        )
      }, TIME_BEFORE_REFRESH_URL)
    )
    setSearchState(updatedSearchState)

    // Looking for daily recipes
    const categoriesTreeString = updatedSearchState.hierarchicalMenu ? updatedSearchState.hierarchicalMenu['categories_tree.lvl0'] || '' : ''

    const state = {
      hitsPerPage: 2,
      query: updatedSearchState.query,
      filters: 'language:fr AND vertical:daily',
      facetFilters: [
        [
          `categories_tree.lvl${
            categoriesTreeString.split(' > ').length - 1
          }:${categoriesTreeString}`,
        ],
      ],
    }
    const response = (await searchIndex.search('', state)).hits
    console.log('Manual response', response.map((i) => i.title))
    setFirstsDailyRecipes(response)
  }

  return (
    <>
      <InstantSearch
        searchClient={searchClient}
        indexName={INDEX_NAME}
        searchState={searchState}
        onSearchStateChange={onSearchStateChange}
        createURL={createURL}
      >
        <SplitedDiv>
          <div className="leftPanel">
            <CustomBreadcrumb
              attributes={[
                'categories_tree.lvl0',
                'categories_tree.lvl1',
                'categories_tree.lvl2',
                'categories_tree.lvl3',
              ]}
              transformItems={(items) => recursiveCategoriesRenaming(items)}
            />

            <CustomSearch />

            <Panel header="Catégories">
              <CustomHierarchicalMenu
                showResetButton
                attributes={[
                  'categories_tree.lvl0',
                  'categories_tree.lvl1',
                  'categories_tree.lvl2',
                  'categories_tree.lvl3',
                ]}
                showParentLevel
                transformItems={(items) => recursiveCategoriesRenaming(items)}
              />
            </Panel>
            <Panel header="Univers">
              <CustomMenu
                attribute="vertical"
                translations={{
                  seeAllOption: 'Voir tout',
                }}
              />
            </Panel>

            <hr className="solid" />
            <Panel header="Temps de préparation">
              <NumericMenu
                attribute="duration_minutes"
                items={[
                  { label: 'Moins de 15 minutes', end: 15 },
                  { label: 'Moins de 30 minutes', end: 30 },
                  { label: 'Moins de 60 minutes', end: 60 },
                ]}
                translations={{
                  all: 'Toute durée',
                }}
              />
            </Panel>
          </div>
          <div className="rightPanel">
            <h2>{pageTitle}</h2>
            <Results
              show
              indexName={INDEX_NAME}
              resultState={resultState}
              firstsDailyRecipes={firstsDailyRecipes}
              localFiles={localFiles}
            />
          </div>
        </SplitedDiv>
      </InstantSearch>
    </>
  )
}

const SplitedDiv = styled.div`
  .ais-Panel-header {
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: 10px;
  }

  .ais-MenuSelect-select {
    width: 100%;
  }

  .leftPanel {
    text-align: left;
    width: 170px;
  }

  .rightPanel {
    max-width: 70%;
  }

  /* Solid border */
  hr.solid {
    border-top: 3px solid #bbb;
  }

  display: flex;
  flex-wrap: wrap;
  column-gap: 10%;
  justify-content: flex-start;
`

export default HitsByCategories
