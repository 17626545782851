import React from 'react'
import { connectBreadcrumb } from 'react-instantsearch-dom'

const Breadcrumb = ({ items, refine, createURL }) => (items.length ? (
  <ul style={{ padding: 0 }}>
    <li style={{ display: 'inline' }}>
      <a
        href={createURL()}
        onClick={(event) => {
          event.preventDefault()
          refine()
        }}
        style={{ textDecoration: 'none', color: 'dimgray', fontWeight: 'bold' }}
      >
        Home
      </a>
    </li>
    {items.map((item) => (
      <li key={item.value} style={{ display: 'inline' }}>
        {'>'}
        <a
          href={createURL(item.value)}
          onClick={(event) => {
            event.preventDefault()
            refine(item.value)
          }}
          style={{ textDecoration: 'none', color: 'dimgray', fontWeight: 'bold' }}
        >
          {item.label}
        </a>
      </li>
    ))}
  </ul>
) : (
  <ul>
    <li style={{ display: 'inline' }}>
        &nbsp;
    </li>
  </ul>
))

const CustomBreadcrumb = connectBreadcrumb(Breadcrumb)
export default CustomBreadcrumb
