import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { connectSearchBox } from 'react-instantsearch-dom'

const SearchBox = ({ currentRefinement, refine }) => {
  const [keepField, setKeepField] = useState('')
  const [avoidField, setAvoidField] = useState('')

  const [keepList, setKeepList] = useState(currentRefinement.split(' ').filter((s) => s.slice(0, 1) !== '-' && s !== ''))
  const [avoidList, setAvoidList] = useState(currentRefinement.split(' ').filter((s) => s.slice(0, 1) === '-' && s !== '-').map((s) => s.slice(1)))

  useEffect(() => {
    console.log(`${keepList.join(' ')} -${avoidList.join(' -')}`)
    refine(`${keepList.join(' ')} -${avoidList.join(' -')}`)
  }, [keepList, avoidList])

  const handleRemove = (value, listSetter) => {
    listSetter((old) => {
      const newTab = [...old]
      const indexValue = newTab.indexOf(value)
      if (indexValue !== -1) {
        newTab.splice(indexValue, 1)
      }
      return newTab
    })
  }

  const handleSubmit = (event, value, valueSetter, listSetter) => {
    event.preventDefault()
    if (value !== '') {
      const newValue = value.replace(/ /g, '')
      listSetter((old) => Array.from(new Set([...old, newValue])))
      valueSetter('')
    }
  }

  return (
    <SearchContainer>
      <h5>Include these ingredients</h5>
      <form noValidate action="" role="search" onSubmit={(e) => handleSubmit(e, keepField, setKeepField, setKeepList)}>
        <input
          type="search"
          value={keepField}
          onChange={(event) => setKeepField(event.currentTarget.value)}
        />
        <button type="submit">+</button>
      </form>
      <div className="itemsContainer">
        {
          keepList.map((item) => (
            <div key={item} className="item keepItem">
              <p className="itemText">{item}</p>
              <div onClick={() => handleRemove(item, setKeepList)} className="itemRemoveButton">
                <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24" fill="white">
                  <path d="M0 0h24v24H0z" fill="none" />
                  <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
                </svg>
              </div>
            </div>
          ))
        }
      </div>
      <h5>Do not include these ingredients</h5>
      <form noValidate action="" role="search" onSubmit={(e) => handleSubmit(e, avoidField, setAvoidField, setAvoidList)}>
        <input
          type="search"
          value={avoidField}
          onChange={(event) => setAvoidField(event.currentTarget.value)}
        />
        <button type="submit">-</button>

      </form>
      <div className="itemsContainer">
        {
          avoidList.map((item) => (
            <div key={item} className="item avoidItem">
              <p className="itemText">{item}</p>
              <div onClick={() => handleRemove(item, setAvoidList)} className="itemRemoveButton">
                <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24" fill="white">
                  <path d="M0 0h24v24H0z" fill="none" />
                  <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
                </svg>
              </div>
            </div>
          ))
        }
      </div>
    </SearchContainer>
  )
}

const SearchContainer = styled.div`

  form {
    width : 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    input {
      width : 80%
    }

    button {
      width : 20%
    }
  }
  
  .itemsContainer{

    width : 100%;
    display: flex;
    align-items: center;
    overflow-x: auto;

    .avoidItem {
      background: red;
    }

    .keepItem {
      background: limegreen;
    }

    .item {
      margin : 5px;
      padding : 5px 5px 5px 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height : 15px;
      border-radius: 50px;
      width : fit-content;
      column-gap: 20px;

      .itemText {
        color : white;
        margin : 0;
      }

      .itemRemoveButton {
        height : 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
  }
  
`

const CustomSearchBox = connectSearchBox(SearchBox)

export default CustomSearchBox
