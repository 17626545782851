import * as React from "react";
import Layout from "../components/layout";
import HitsByCategories from "../components/POC1/HitsByCategories";
import { graphql } from "gatsby";
import {useEffect} from "react";

const BlogPage = ({ location, data }) => {
	
	useEffect(() => {
		//console.log("location.pathname", location.pathname)
	}, [location])
	
    return (
        <Layout
            pageTitle={data.sitePage.context.pageTitle}
            slug={data.sitePage.context.slug}
        >
            <HitsByCategories
                pathname={
                    data &&
                    data.sitePage &&
                    data.sitePage.context &&
                    data.sitePage.context.slug &&
                    data.sitePage.context.slug !== ""
                        ? data.sitePage.context.slug
                        : location
                        ? location.pathname
                        : ""
                }
                hash={location ? location.hash : ""}
                search={location ? location.search : null}
                resultState={
                    data &&
                    data.sitePage &&
                    data.sitePage.context &&
                    data.sitePage.context.hits &&
                    data.sitePage.context.nbHits
                        ? {hits : data.sitePage.context.hits, nbHits : data.sitePage.context.nbHits}
                        : null
                }
                dailyHits={
	                data &&
	                data.sitePage &&
	                data.sitePage.context &&
	                data.sitePage.context.dailyHits
		                ? data.sitePage.context.dailyHits
		                : null
                }
                searchState={
	                data &&
	                data.sitePage &&
	                data.sitePage.context &&
	                data.sitePage.context.searchState
		                ? JSON.parse(data.sitePage.context.searchState)
		                : null
                }
                localFiles={
	                data &&
	                data.sitePage &&
	                data.sitePage.localFile ? data.sitePage.localFile : null
                }
            />
        </Layout>
    );
};

export default BlogPage;

export const query = graphql`
    query ($slug: String!) {
        sitePage(context: { slug: { eq: $slug } }) {
            id
            component
            context {
                slug
                nbHits
                searchState
                hits {
                    absolute_url
                    web_cover_thumbnail
                    title
                    subtitle
                    objectID
                    id
                }
                dailyHits {
                    absolute_url
                    web_cover_thumbnail
                    title
                    subtitle
                    objectID
                    id
                }
                pageTitle
            }
            localFile {
                name,
              childImageSharp {
                gatsbyImageData(width: 190, placeholder: BLURRED)
              }
        }
    }
}
`;
