import React from 'react'
import { Highlight, connectMenu } from 'react-instantsearch-dom'

const Menu = ({
  items, isFromSearch, refine, createURL,
}) => {
  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1)
  }
  return (
    <ul style={{ padding: 0 }}>
      {items.map((item) => (
        <li key={item.value} style={{ display: 'inline' }}>
          <a
            href={`/c/${createURL(item.value)}`}
            style={{
              fontWeight: item.isRefined ? '800' : '500',
              textDecoration: 'none',
              color: 'cornflowerblue',
            }}
            onClick={(event) => {
              event.preventDefault()
              refine(item.value)
            }}
          >
            {isFromSearch ? (
              <Highlight attribute="label" hit={item} />
            ) : (
              capitalizeFirstLetter(item.label)
            )}
            <br />
          </a>
        </li>
      ))}
    </ul>
  )
}

const CustomMenu = connectMenu(Menu)
export default CustomMenu
