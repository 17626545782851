import qs from 'qs'
import { getCategoriesByName, getCategoryById } from './categoriesUtils'

const WITHOUT_URL_MODIF = false // On/Off for using Algolia's version
const ENCODED_CATEGORIES = {
  p: 'page',
  v: 'menu.vertical',
  d: 'multiRange.duration_minutes'
}

const createURL = (state) => {
  // console.log('CreateURL', 'state', state)

  let url = ''

  if (WITHOUT_URL_MODIF) {
    url = `?${qs.stringify(state)}`
  } else {
    if (state.hierarchicalMenu) {
      let categoriesPart = ''

      if (state.hierarchicalMenu['categories_tree.lvl0'] && state.hierarchicalMenu['categories_tree.lvl0'] !== '') {
        // console.log("CreateURL", "state.hierarchicalMenu.categories_tree.lvl0", state.hierarchicalMenu["categories_tree.lvl0"]);
        categoriesPart = state.hierarchicalMenu['categories_tree.lvl0'].split(' > ').map((categoryId) => getCategoryById(categoryId).name).join('/')
      }

      url = categoriesPart
    }

    const hashPart = []
    if (state.menu) {
      let verticalPart = ''

      if (state.menu.vertical && state.menu.vertical !== '') {
        // console.log("CreateURL", "state.menu.vertical", state.menu.vertical);
        verticalPart = `v-${state.menu.vertical}`
      }

      if (verticalPart !== '') {
        hashPart.push(verticalPart)
      }
    }
    if (state.multiRange) {
      let durationPart = ''

      if (state.multiRange.duration_minutes && state.multiRange.duration_minutes !== '') {
        // console.log("CreateURL", "state.menu.vertical", state.menu.vertical);
        durationPart = `d-${state.multiRange.duration_minutes}`
      }

      if (durationPart !== '') {
        hashPart.push(durationPart)
      }
    }

    if (hashPart.length > 0) {
      url = `${url}#${hashPart.join(',')}`
    }
  }
  // console.log("CreateURL", "url", url);
  return url
}

function searchStateToUrl(searchState) {
  // console.log("searchStateToUrl", "searchState", searchState);

  let url = ''

  if (searchState) {
    if (WITHOUT_URL_MODIF) {
      url = `${window.location.pathname}${createURL(searchState)}`
    } else {
      url = `/c/${createURL(searchState)}`
    }
  }

  // console.log("searchStateToUrl", "url", url);
  return url
}

const urlToSearchState = (search, pathname, hash) => {
  // console.log("urlToSearchState", "search", search);
  // console.log("urlToSearchState", "pathname", pathname);
  // console.log("urlToSearchState", "hash", hash);

  let searchState = {}

  if (WITHOUT_URL_MODIF) {
    // console.log("qs.parse : ", qs.parse(search.slice(1)));
    searchState = qs.parse(search.slice(1))
  } else {
    const hierarchicalMenu = {}

    // console.log(pathname.split('/'))
    const categoriesStrings = pathname.split('/').slice(2).map((cat) => decodeURI(cat)).filter((cat) => cat !== '' && cat)
    // console.log("categoriesStrings", categoriesStrings)
    const categories = categoriesStrings.map((categoryName) => {
      const matches = getCategoriesByName(categoryName)
      if (matches.length > 0) {
        return matches[0].id
      }
      return ''
    })
    if (categories.length > 0) {
      hierarchicalMenu['categories_tree.lvl0'] = categories.join(' > ')
    }

    searchState = {
      ...searchState,
      hierarchicalMenu
    }

    if (hash) {
      const hashParams = hash.slice(1).split(',')

      for (const param of hashParams) {
        const splitedParam = param.split('-')
        const paramEncodedCategory = splitedParam[0]
        const paramValue = splitedParam[1]

        if (ENCODED_CATEGORIES[paramEncodedCategory]) {
          const decodedCategoryPath = ENCODED_CATEGORIES[paramEncodedCategory]
          console.log('urlToSearchState', 'decodedCategory', decodedCategoryPath)

          const idList = decodedCategoryPath.split('.')
          const lastId = idList.splice(-1)[0]
          let lastBase = searchState
          for (const id of idList) {
            if (!lastBase[id]) lastBase[id] = {}
            lastBase = lastBase[id]
          }

          if (!isNaN(+paramValue)) {
            lastBase[lastId] = +paramValue
          } else {
            lastBase[lastId] = paramValue
          }
        }
      }
    }
  }
  console.log('urlToSearchState', 'searchState', searchState)

  return searchState
}

export {
  createURL,
  searchStateToUrl,
  urlToSearchState
}
