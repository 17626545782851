import { connectHierarchicalMenu } from 'react-instantsearch-dom'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

const HierarchicalMenu = ({
  items,
  currentRefinement,
  refine,
  createURL,
  showResetButton = false,
}) => {
  const [isLastLevel, setIsLastLevel] = useState(false)
  const [displayedItems, setDisplayedItems] = useState([])

  useEffect(() => {
    const withChildrenItems = items.filter((item) => item.items)
    if (withChildrenItems.length === 0) {
      setIsLastLevel(true)
      setDisplayedItems(items)
    } else {
      setIsLastLevel(false)
      setDisplayedItems(withChildrenItems)
    }
  }, [items, currentRefinement])

  const resetCategoriesHandler = (event) => {
    event.preventDefault()
    refine('')
  }

  return (
    <RootContainer isLastLevel={isLastLevel && !showResetButton}>
      {showResetButton && currentRefinement && (
        <a
          href={createURL('')}
          onClick={resetCategoriesHandler}
        >
          {'<'} Toutes les catégories
        </a>
      )}
      {displayedItems.map((item, index) => (
        <div key={index.value}>
          <a
            href={`/c/${createURL(item.value)}`}
            style={{
              fontWeight: item.isRefined ? 'bold' : '',
              color: item.items
                ? item.items.filter((i) => i.items).length > 0
                  ? 'skyblue'
                  : 'orange'
                : 'red',
            }}
            onClick={(event) => {
              event.preventDefault()

              // item.items est undefined quand tu descend
              const goBack = !!item.items
              // items
              const hasChildRefined = !!items.filter((itemOfItems) => itemOfItems.isRefined)

              let goValue = item.value

              if (goBack && hasChildRefined) {
                const childRefined = items.filter((itemOfItems) => itemOfItems.isRefined)[0]
                // If his childRefined has a childRefined, we have to go to his value
                const childRefinedOfChildRefined = childRefined.items.filter((itemOfItems) => itemOfItems.isRefined)[0] ?? null

                if (childRefinedOfChildRefined) {
                  goValue = childRefinedOfChildRefined.value
                  // console.log("Item refined of refined found : ", childRefinedOfChildRefined);
                  refine(goValue)
                }
              } else {
                // console.log("Aucun item refined found : ");
                refine(goValue)
              }
            }}

          >
            {item.items && item.items.filter((i) => i.items).length > 0
              ? '<'
              : ''}
            {item.label}
            {!item.items && `(${item.count})`}
          </a>
          {item.items && (
            <HierarchicalMenu
              items={item.items}
              refine={refine}
              createURL={createURL}
            />
          )}
        </div>
      ))}
    </RootContainer>
  )
}

const RootContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: ${({ isLastLevel }) => (isLastLevel ? '25px' : '0px')};
  a {
    text-decoration: none;
  }
`

const CustomHierarchicalMenu = connectHierarchicalMenu(HierarchicalMenu)

export default CustomHierarchicalMenu
