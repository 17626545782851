/* eslint-disable no-prototype-builtins */
/* eslint-disable no-plusplus */
/* eslint-disable no-restricted-syntax */
const CATEGORIES = {
  'c7e1249f-fc03-eb9d-ed90-8c236bd1996d': {
    name: 'Bûche',
    parent: null,
  },
  '2a38a4a9-316c-49e5-a833-517c45d31070': {
    name: 'Farcis',
    parent: null,
  },
  '093f65e0-80a2-95f8-076b-1c5722a46aa2': {
    name: 'GENERAL',
    parent: null,
  },
  '98dce83d-a57b-0395-e163-467c9dae521b': {
    name: 'New_categories',
    parent: null,
  },

  '42a0e188-f503-3bc6-5bf8-d78622277c4e': {
    name: 'Salé',
    parent: '98dce83d-a57b-0395-e163-467c9dae521b',
  },

  '2a79ea27-c279-e471-f4d1-80b08d62b00a': {
    name: 'Apéros',
    parent: '42a0e188-f503-3bc6-5bf8-d78622277c4e',
  },
  '1c9ac015-9c94-d8d0-cbed-c973445af2da': {
    name: 'Bouchées',
    parent: '2a79ea27-c279-e471-f4d1-80b08d62b00a',
  },
  '06409663-226a-f2f3-1144-85aa4e0a23b4': {
    name: 'Feuilleté',
    parent: '2a79ea27-c279-e471-f4d1-80b08d62b00a',
  },
  '140f6969-d521-3fd0-ece0-3148e62e461e': {
    name: 'Pièce montée salée',
    parent: '2a79ea27-c279-e471-f4d1-80b08d62b00a',
  },
  '6c4b761a-28b7-34fe-9383-1e3fb400ce87': {
    name: 'Croquettes',
    parent: '2a79ea27-c279-e471-f4d1-80b08d62b00a',
  },
  '757b505c-fd34-c64c-85ca-5b5690ee5293': {
    name: 'Croquettes de fromage',
    parent: '6c4b761a-28b7-34fe-9383-1e3fb400ce87',
  },
  '3644a684-f98e-a8fe-223c-713b77189a77': {
    name: 'Croquettes de légumes',
    parent: '6c4b761a-28b7-34fe-9383-1e3fb400ce87',
  },
  '854d6fae-5ee4-2911-677c-739ee1734486': {
    name: 'Croquettes de viande',
    parent: '6c4b761a-28b7-34fe-9383-1e3fb400ce87',
  },
  'e2c0be24-560d-78c5-e599-c2a9c9d0bbd2': {
    name: 'Autres croquettes',
    parent: '6c4b761a-28b7-34fe-9383-1e3fb400ce87',
  },
  'ec895663-7a99-787b-d197-eacd77acce5e': {
    name: 'Fromages',
    parent: '42a0e188-f503-3bc6-5bf8-d78622277c4e',
  },
  'a97da629-b098-b75c-294d-ffdc3e463904': {
    name: 'Camembert',
    parent: 'ec895663-7a99-787b-d197-eacd77acce5e',
  },
  '6974ce5a-c660-610b-44d9-b9fed0ff9548': {
    name: 'Fondue',
    parent: 'ec895663-7a99-787b-d197-eacd77acce5e',
  },
  'f0935e4c-d592-0aa6-c7c9-96a5ee53a70f': {
    name: "Mont-d'or",
    parent: 'ec895663-7a99-787b-d197-eacd77acce5e',
  },
  'c9e1074f-5b3f-9fc8-ea15-d152add07294': {
    name: 'Raclette',
    parent: 'ec895663-7a99-787b-d197-eacd77acce5e',
  },
  '65b9eea6-e1cc-6bb9-f0cd-2a47751a186f': {
    name: 'Tartiflette',
    parent: 'ec895663-7a99-787b-d197-eacd77acce5e',
  },
  'a8baa565-54f9-6369-ab93-e4f3bb068c22': {
    name: 'Gâteaux salés',
    parent: '42a0e188-f503-3bc6-5bf8-d78622277c4e',
  },
  '903ce922-5fca-3e98-8c2a-f215d4e544d3': {
    name: 'Bûche salée',
    parent: 'a8baa565-54f9-6369-ab93-e4f3bb068c22',
  },
  '0a09c884-4ba8-f093-6c20-bd791130d6b6': {
    name: 'Cake salé',
    parent: 'a8baa565-54f9-6369-ab93-e4f3bb068c22',
  },
  '2b24d495-052a-8ce6-6358-eb576b8912c8': {
    name: 'Couronne salée',
    parent: 'a8baa565-54f9-6369-ab93-e4f3bb068c22',
  },
  'a5e00132-373a-7031-000f-d987a3c9f87b': {
    name: 'Crêpe salée',
    parent: 'a8baa565-54f9-6369-ab93-e4f3bb068c22',
  },
  '8d5e957f-2978-9348-7bd9-8fa830fa6413': {
    name: 'Gaufre salée',
    parent: 'a8baa565-54f9-6369-ab93-e4f3bb068c22',
  },
  '47d1e990-583c-9c67-424d-369f3414728e': {
    name: 'Terrine',
    parent: 'a8baa565-54f9-6369-ab93-e4f3bb068c22',
  },
  '3988c7f8-8ebc-b58c-6ce9-32b957b6f332': {
    name: 'Gratins',
    parent: '42a0e188-f503-3bc6-5bf8-d78622277c4e',
  },
  '013d4071-66ec-4fa5-6eb1-e1f8cbe183b9': {
    name: 'Gratin',
    parent: '3988c7f8-8ebc-b58c-6ce9-32b957b6f332',
  },
  '85d8ce59-0ad8-981c-a2c8-286f79f59954': {
    name: 'Gratin dauphinois',
    parent: '3988c7f8-8ebc-b58c-6ce9-32b957b6f332',
  },
  'e00da03b-685a-0dd1-8fb6-a08af0923de0': {
    name: 'Hachis Parmentier',
    parent: '3988c7f8-8ebc-b58c-6ce9-32b957b6f332',
  },
  '1385974e-d590-4a43-8616-ff7bdb3f7439': {
    name: 'Moussaka',
    parent: '3988c7f8-8ebc-b58c-6ce9-32b957b6f332',
  },
  '0f28b5d4-9b30-20af-eecd-95b4009adf4c': {
    name: 'Welsh',
    parent: '3988c7f8-8ebc-b58c-6ce9-32b957b6f332',
  },
  '4c56ff4c-e4aa-f957-3aa5-dff913df997a': {
    name: 'Légumes',
    parent: '42a0e188-f503-3bc6-5bf8-d78622277c4e',
  },
  'a0a080f4-2e6f-13b3-a2df-133f073095dd': {
    name: 'Purée',
    parent: '4c56ff4c-e4aa-f957-3aa5-dff913df997a',
  },
  '202cb962-ac59-075b-964b-07152d234b70': {
    name: 'Salade',
    parent: '4c56ff4c-e4aa-f957-3aa5-dff913df997a',
  },
  'c8ffe9a5-87b1-26f1-52ed-3d89a146b445': {
    name: 'Soupe',
    parent: '4c56ff4c-e4aa-f957-3aa5-dff913df997a',
  },
  '3def184a-d8f4-755f-f269-862ea77393dd': {
    name: 'Tomates mozzarella',
    parent: '4c56ff4c-e4aa-f957-3aa5-dff913df997a',
  },
  'f4b9ec30-ad9f-68f8-9b29-639786cb62ef': {
    name: 'Pâtes et Riz',
    parent: '42a0e188-f503-3bc6-5bf8-d78622277c4e',
  },
  '812b4ba2-87f5-ee0b-c9d4-3bbf5bbe87fb': {
    name: 'Bolognaise',
    parent: '4c56ff4c-e4aa-f957-3aa5-dff913df997a',
  },
  '26657d5f-f902-0d2a-befe-558796b99584': {
    name: 'Carbonara',
    parent: '4c56ff4c-e4aa-f957-3aa5-dff913df997a',
  },
  'ac627ab1-ccbd-b62e-c96e-702f07f6425b': {
    name: 'Gratin de pâtes',
    parent: '4c56ff4c-e4aa-f957-3aa5-dff913df997a',
  },
  'ed3d2c21-991e-3bef-5e06-9713af9fa6ca': {
    name: 'Lasagnes',
    parent: '4c56ff4c-e4aa-f957-3aa5-dff913df997a',
  },
  'f899139d-f5e1-0593-9643-1415e770c6dd': {
    name: 'Risotto',
    parent: '4c56ff4c-e4aa-f957-3aa5-dff913df997a',
  },
  '38b3eff8-baf5-6627-478e-c76a704e9b52': {
    name: 'Sushi',
    parent: '4c56ff4c-e4aa-f957-3aa5-dff913df997a',
  },
  'a3c65c29-7427-0fd0-93ee-8a9bf8ae7d0b': {
    name: 'Viandes et œufs',
    parent: '42a0e188-f503-3bc6-5bf8-d78622277c4e',
  },
  '2723d092-b638-85e0-d7c2-60cc007e8b9d': {
    name: 'Agneau',
    parent: 'a3c65c29-7427-0fd0-93ee-8a9bf8ae7d0b',
  },
  '5f93f983-524d-ef3d-ca46-4469d2cf9f3e': {
    name: 'Boulettes',
    parent: 'a3c65c29-7427-0fd0-93ee-8a9bf8ae7d0b',
  },
  '698d51a1-9d8a-121c-e581-499d7b701668': {
    name: 'Brochettes',
    parent: 'a3c65c29-7427-0fd0-93ee-8a9bf8ae7d0b',
  },
  '7f6ffaa6-bb0b-4080-17b6-2254211691b5': {
    name: 'Cordon bleu',
    parent: 'a3c65c29-7427-0fd0-93ee-8a9bf8ae7d0b',
  },
  '73278a4a-8696-0eeb-576a-8fd4c9ec6997': {
    name: 'Farcis',
    parent: 'a3c65c29-7427-0fd0-93ee-8a9bf8ae7d0b',
  },
  '5fd0b37c-d7db-bb00-f97b-a6ce92bf5add': {
    name: 'Kebab',
    parent: 'a3c65c29-7427-0fd0-93ee-8a9bf8ae7d0b',
  },
  '2b44928a-e11f-b938-4c4c-f38708677c48': {
    name: 'Œufs',
    parent: 'a3c65c29-7427-0fd0-93ee-8a9bf8ae7d0b',
  },
  'c45147de-e729-311e-f5b5-c3003946c48f': {
    name: 'Omelette',
    parent: 'a3c65c29-7427-0fd0-93ee-8a9bf8ae7d0b',
  },
  'eb160de1-de89-d905-8fcb-0b968dbbbd68': {
    name: 'Steak',
    parent: 'a3c65c29-7427-0fd0-93ee-8a9bf8ae7d0b',
  },
  '5ef05993-8ba7-99aa-a845-e1c2e8a762bd': {
    name: 'Volaille',
    parent: 'a3c65c29-7427-0fd0-93ee-8a9bf8ae7d0b',
  },
  'f2217062-e9a3-97a1-dca4-29e7d70bc6ca': {
    name: 'Sandwichs',
    parent: '42a0e188-f503-3bc6-5bf8-d78622277c4e',
  },
  '7ef605fc-8dba-5425-d696-5fbd4c8fbe1f': {
    name: 'Burger',
    parent: 'f2217062-e9a3-97a1-dca4-29e7d70bc6ca',
  },
  'a8f15eda-80c5-0adb-0e71-943adc8015cf': {
    name: 'Croque-monsieur',
    parent: 'f2217062-e9a3-97a1-dca4-29e7d70bc6ca',
  },
  '37a749d8-08e4-6495-a8da-1e5352d03cae': {
    name: 'Hot dog',
    parent: 'f2217062-e9a3-97a1-dca4-29e7d70bc6ca',
  },
  'b3e3e393-c77e-35a4-a3f3-cbd1e429b5dc': {
    name: 'Sandwich',
    parent: 'f2217062-e9a3-97a1-dca4-29e7d70bc6ca',
  },
  '1d7f7abc-18fc-b439-7506-5399b0d1e48e': {
    name: 'Tacos',
    parent: 'f2217062-e9a3-97a1-dca4-29e7d70bc6ca',
  },
  '069059b7-ef84-0f0c-74a8-14ec9237b6ec': {
    name: 'Tartes, Quiches et Pizza',
    parent: '42a0e188-f503-3bc6-5bf8-d78622277c4e',
  },
  'ec5decca-5ed3-d6b8-079e-2e7e7bacc9f2': {
    name: 'Pizza',
    parent: '069059b7-ef84-0f0c-74a8-14ec9237b6ec',
  },
  '76dc611d-6eba-afc6-6cc0-879c71b5db5c': {
    name: 'Quiche',
    parent: '069059b7-ef84-0f0c-74a8-14ec9237b6ec',
  },
  'd1f491a4-04d6-8548-8094-3e5c3cd9ca25': {
    name: 'Tarte salée',
    parent: '069059b7-ef84-0f0c-74a8-14ec9237b6ec',
  },
  'eae27d77-ca20-db30-9e05-6e3d2dcd7d69': {
    name: 'Tarte saumon',
    parent: 'd1f491a4-04d6-8548-8094-3e5c3cd9ca25',
  },
  '1afa34a7-f984-eeab-dbb0-a7d494132ee5': {
    name: 'Tartine',
    parent: '069059b7-ef84-0f0c-74a8-14ec9237b6ec',
  },

  'b73ce398-c39f-506a-f761-d2277d853a92': {
    name: 'Sucré',
    parent: '98dce83d-a57b-0395-e163-467c9dae521b',
  },
  '31fefc0e-570c-b386-0f2a-6d4b38c6490d': {
    name: 'Autres plats sucrés',
    parent: 'b73ce398-c39f-506a-f761-d2277d853a92',
  },
  '9dcb88e0-1376-4959-0b75-5372b040afad': {
    name: 'Crêpe',
    parent: '31fefc0e-570c-b386-0f2a-6d4b38c6490d',
  },
  'a2557a7b-2e94-197f-f767-970b67041697': {
    name: 'Fondue sucrée',
    parent: '31fefc0e-570c-b386-0f2a-6d4b38c6490d',
  },
  'cfecdb27-6f63-4854-f3ef-915e2e980c31': {
    name: 'Glace',
    parent: '31fefc0e-570c-b386-0f2a-6d4b38c6490d',
  },
  '0aa1883c-6411-f787-3cb8-3dacb17b0afc': {
    name: 'Petit-déjeuner',
    parent: '31fefc0e-570c-b386-0f2a-6d4b38c6490d',
  },
  '045117b0-e0a1-1a24-2b97-65e79cbf113f': {
    name: 'Chocolat',
    parent: 'b73ce398-c39f-506a-f761-d2277d853a92',
  },
  'fc221309-7460-13ac-5545-71fbd180e1c8': {
    name: 'Dessert au chocolat',
    parent: '045117b0-e0a1-1a24-2b97-65e79cbf113f',
  },
  '4c5bde74-a8f1-1065-6874-902f07378009': {
    name: 'Fondant au chocolat',
    parent: '045117b0-e0a1-1a24-2b97-65e79cbf113f',
  },
  '6cdd60ea-0045-eb7a-6ec4-4c54d29ed402': {
    name: 'Pâtisserie',
    parent: '045117b0-e0a1-1a24-2b97-65e79cbf113f',
  },
  'cedebb6e-872f-539b-ef8c-3f919874e9d7': {
    name: 'Desserts pâtissiers',
    parent: 'b73ce398-c39f-506a-f761-d2277d853a92',
  },
  'eecca5b6-365d-9607-ee5a-9d336962c534': {
    name: 'Tarte sucrée',
    parent: 'cedebb6e-872f-539b-ef8c-3f919874e9d7',
  },
  '9872ed9f-c22f-c182-d371-c3e9ed316094': {
    name: 'Tiramisu',
    parent: 'cedebb6e-872f-539b-ef8c-3f919874e9d7',
  },
  '36366388-1777-2e42-b59d-74cff571fbb3': {
    name: 'Fruits',
    parent: 'b73ce398-c39f-506a-f761-d2277d853a92',
  },
  '149e9677-a598-9fd3-42ae-44213df68868': {
    name: 'Dessert à la banane',
    parent: '36366388-1777-2e42-b59d-74cff571fbb3',
  },
  'a4a042cf-4fd6-bfb4-7701-cbc8a1653ada': {
    name: 'Dessert à la fraise',
    parent: '36366388-1777-2e42-b59d-74cff571fbb3',
  },
  '1ff8a7b5-dc7a-7d1f-0ed6-5aaa29c04b1e': {
    name: 'Dessert à la poire',
    parent: '36366388-1777-2e42-b59d-74cff571fbb3',
  },
  'f7e6c855-04ce-6e82-442c-770f7c8606f0': {
    name: 'Dessert à la pomme',
    parent: '36366388-1777-2e42-b59d-74cff571fbb3',
  },
  'bf822969-6f7a-3bb4-700c-fddef19fa23f': {
    name: 'Dessert fruité',
    parent: '36366388-1777-2e42-b59d-74cff571fbb3',
  },
  'bd4c9ab7-30f5-5132-06b9-99ec0d90d1fb': {
    name: 'Gâteaux',
    parent: 'b73ce398-c39f-506a-f761-d2277d853a92',
  },
  '82aa4b0a-f34c-2313-a562-076992e50aa3': {
    name: 'Brioche',
    parent: 'bd4c9ab7-30f5-5132-06b9-99ec0d90d1fb',
  },
  '0777d5c1-7d40-66b8-2ab8-6dff8a46af6f': {
    name: 'Bûche',
    parent: 'bd4c9ab7-30f5-5132-06b9-99ec0d90d1fb',
  },
  'fa7cdfad-1a5a-af83-70eb-eda47a1ff1c3': {
    name: 'Cheesecake',
    parent: 'bd4c9ab7-30f5-5132-06b9-99ec0d90d1fb',
  },
  '9766527f-2b5d-3e95-d4a7-33fcfb77bd7e': {
    name: 'Gros gâteau',
    parent: 'bd4c9ab7-30f5-5132-06b9-99ec0d90d1fb',
  },
  '006f52e9-102a-8d3b-e2fe-5614f42ba989': {
    name: 'Panettone',
    parent: 'bd4c9ab7-30f5-5132-06b9-99ec0d90d1fb',
  },
  '82161242-827b-703e-6acf-9c726942a1e4': {
    name: 'Petits gâteaux',
    parent: 'b73ce398-c39f-506a-f761-d2277d853a92',
  },
  '38af8613-4b65-d0f1-0fe3-3d30dd76442e': {
    name: 'Biscuits',
    parent: '82161242-827b-703e-6acf-9c726942a1e4',
  },
  '96da2f59-0cd7-246b-bde0-051047b0d6f7': {
    name: 'Churros',
    parent: '82161242-827b-703e-6acf-9c726942a1e4',
  },
  '8f53295a-7387-8494-e9bc-8dd6c3c7104f': {
    name: 'Cookies',
    parent: '82161242-827b-703e-6acf-9c726942a1e4',
  },
  '7e7757b1-e12a-bcb7-36ab-9a754ffb617a': {
    name: 'Madeleines',
    parent: '82161242-827b-703e-6acf-9c726942a1e4',
  },
  '5878a7ab-84fb-4340-2106-c575658472fa': {
    name: 'Muffin',
    parent: '82161242-827b-703e-6acf-9c726942a1e4',
  },

  'bd686fd6-40be-98ef-aae0-091fa301e613': {
    name: 'Astuce',
    parent: '98dce83d-a57b-0395-e163-467c9dae521b',
  },
  '274ad478-6c3a-bca6-9fa0-97b85867d9a4': {
    name: 'Astuces',
    parent: 'bd686fd6-40be-98ef-aae0-091fa301e613',
  },
  'a597e505-02f5-ff68-e3e2-5b9114205d4a': {
    name: 'Astuces cocktails',
    parent: '274ad478-6c3a-bca6-9fa0-97b85867d9a4',
  },
  '0336dcba-b05b-9d5a-d24f-4333c7658a0e': {
    name: 'Astuces salées',
    parent: '274ad478-6c3a-bca6-9fa0-97b85867d9a4',
  },
  '084b6fbb-1072-9ed4-da8c-3d3f5a3ae7c9': {
    name: 'Astuces sucrées',
    parent: '274ad478-6c3a-bca6-9fa0-97b85867d9a4',
  },

  '58a2fc6e-d39f-d083-f55d-4182bf88826d': {
    name: 'Cocktail',
    parent: '98dce83d-a57b-0395-e163-467c9dae521b',
  },
  '8613985e-c49e-b8f7-57ae-6439e879bb2a': {
    name: 'Glace',
    parent: null,
  },
  '07e1cd7d-ca89-a167-8042-477183b7ac3f': {
    name: 'Event',
    parent: null,
  },
  '7f39f831-7fbd-b198-8ef4-c628eba02591': {
    name: 'Noël',
    parent: '07e1cd7d-ca89-a167-8042-477183b7ac3f',
  },
  'a3f390d8-8e4c-41f2-747b-fa2f1b5f87db': {
    name: 'Saint-Valentin',
    parent: '07e1cd7d-ca89-a167-8042-477183b7ac3f',
  },
  '54229abf-cfa5-649e-7003-b83dd4755294': {
    name: 'Pâques',
    parent: '07e1cd7d-ca89-a167-8042-477183b7ac3f',
  },
  '072b030b-a126-b2f4-b237-4f342be9ed44': {
    name: 'Halloween',
    parent: '07e1cd7d-ca89-a167-8042-477183b7ac3f',
  },
  '0e65972d-ce68-dad4-d52d-063967f0a705': {
    name: 'Compil',
    parent: null,
  },
  '84d9ee44-e457-ddef-7f2c-4f25dc8fa865': {
    name: 'PERDU',
    parent: null,
  },
  'da4fb5c6-e93e-74d3-df85-27599fa62642': {
    name: 'Collections',
    parent: null,
  },
  '735b90b4-5681-25ed-6c3f-678819b6e058': {
    name: 'Hachis parmentier',
    parent: 'da4fb5c6-e93e-74d3-df85-27599fa62642',
  },
  'fc490ca4-5c00-b124-9bbe-3554a4fdf6fb': {
    name: 'Fondant au chocolat',
    parent: 'da4fb5c6-e93e-74d3-df85-27599fa62642',
  },
  '03afdbd6-6e79-29b1-25f8-597834fa83a4': {
    name: 'Pizza',
    parent: 'da4fb5c6-e93e-74d3-df85-27599fa62642',
  },
  'ea5d2f1c-4608-232e-07d3-aa3d998e5135': {
    name: 'Quiche',
    parent: 'da4fb5c6-e93e-74d3-df85-27599fa62642',
  },
  '7cbbc409-ec99-0f19-c78c-75bd1e06f215': {
    name: 'quiche lorraine',
    parent: 'ea5d2f1c-4608-232e-07d3-aa3d998e5135',
  },
  '14bfa6bb-1487-5e45-bba0-28a21ed38046': {
    name: 'Crêpes',
    parent: 'da4fb5c6-e93e-74d3-df85-27599fa62642',
  },
  '3295c76a-cbf4-caae-d33c-36b1b5fc2cb1': {
    name: 'Cordon bleu',
    parent: 'da4fb5c6-e93e-74d3-df85-27599fa62642',
  },
  'fbd7939d-6749-97cd-b469-2d34de8633c4': {
    name: 'Omelette',
    parent: null,
  },
  'fe9fc289-c3ff-0af1-42b6-d3bead98a923': {
    name: 'Petits fours',
    parent: null,
  },
  '9778d5d2-19c5-080b-9a6a-17bef029331c': {
    name: 'Purée',
    parent: null,
  },
  'ad61ab14-3223-efbc-24c7-d2583be69251': {
    name: 'Raclette',
    parent: null,
  },
  '3ef81541-6f77-5098-fe97-7004015c6193': {
    name: 'Salade',
    parent: null,
  },
  '7647966b-7343-c290-4867-3252e490f736': {
    name: 'Sushis',
    parent: null,
  },
  'd2ddea18-f006-65ce-8623-e36bd4e3c7c5': {
    name: 'Tartiflette',
    parent: null,
  },
  'f033ab37-c302-01f7-3f14-2449d037028d': {
    name: 'Tartine',
    parent: null,
  },
  '43ec517d-68b6-edd3-015b-3edc9a11367b': {
    name: 'Terrine',
    parent: null,
  },
  'd09bf415-44a3-365a-46c9-077ebb5e35c3': {
    name: 'Tiramisu',
    parent: null,
  },
};

const getCategoryById = (id) => {
  if (CATEGORIES.hasOwnProperty(id)) {
    return { ...CATEGORIES[id], id };
  }
  return false;
};

const getCategoriesByName = (name) => {
  const normalizedName = name.normalize().toUpperCase();
  const matchedCategories = [];

  for (const idCat in CATEGORIES) {
    if (normalizedName === CATEGORIES[idCat].name.normalize().toUpperCase()) {
      matchedCategories.push({ ...CATEGORIES[idCat], id: idCat });
    }
  }

  return matchedCategories;
};

const getSubCategoriesById = (id) => {
  const subCategories = [];

  for (const idCat in CATEGORIES) {
    if (id === CATEGORIES[idCat].parent) {
      subCategories.push({ ...CATEGORIES[idCat], id: idCat });
    }
  }

  return subCategories;
};

const getCategoryPathById = (id, startCrop = 0, endCrop = 0) => {
  let actualCategory = getCategoryById(id);
  let path = [actualCategory];
  while (actualCategory.parent) {
    actualCategory = getCategoryById(actualCategory.parent);
    path.unshift(actualCategory);
  }
  path = path.slice(startCrop).slice(-endCrop);
  return path;
};

const getBreadcrumbById = (id, attribute, separator = '>', startCrop = 0, endCrop = 0) => {
  const path = getCategoryPathById(id, startCrop, endCrop);
  return path.map((item) => item[attribute]).join(` ${separator} `);
};

const getNameBreadcrumbById = (id, separator = '>', startCrop = 0, endCrop = 0) => getBreadcrumbById(id, 'name', separator, startCrop, endCrop);

const getIdBreadcrumbById = (id, separator = '>', startCrop = 0, endCrop = 0) => getBreadcrumbById(id, 'id', separator, startCrop, endCrop);

const getCategoriesTreeValueById = (id) => {
  const path = getCategoryPathById(id, 1).map((item) => item.id);

  if (path.length > 0) {
    const categoriesTree = {};

    for (let i = 0; i < path.length; i++) {
      categoriesTree[`lvl${i}`] = getIdBreadcrumbById(path[i], '>', 1);
    }

    return categoriesTree;
  }

  return false;
};

const recursiveCategoriesRenaming = (items) => items.map((item) => ({
  ...item,
  label: getCategoryById(item.label).name,
  items: item.items ? recursiveCategoriesRenaming(item.items) : null,
}));

module.exports = {
  CATEGORIES,
  getCategoryById,
  getCategoriesByName,
  getSubCategoriesById,
  getCategoryPathById,
  getNameBreadcrumbById,
  getIdBreadcrumbById,
  getCategoriesTreeValueById,
  recursiveCategoriesRenaming,
};

/*

[
  {
    "title": "Salé",
    "subtitle": "-1",
    "categories_tree": {
      "lvl0" : "42a0e188-f503-3bc6-5bf8-d78622277c4e",
      "lvl1" : "",
      "lvl2" : "",
      "lvl3" : ""
    }
  },
  {
    "title": "Salé > Apéros > Bouchées",
    "subtitle": "0",
    "categories_tree": {
      "lvl0" : "42a0e188-f503-3bc6-5bf8-d78622277c4e",
      "lvl1" : "2a79ea27-c279-e471-f4d1-80b08d62b00a",
      "lvl2" : "",
      "lvl3" : ""
    }
  },
  {
    "title": "Salé > Apéros > Bouchées",
    "subtitle": "1",
    "categories_tree": {
      "lvl0" : "42a0e188-f503-3bc6-5bf8-d78622277c4e",
      "lvl1" : "2a79ea27-c279-e471-f4d1-80b08d62b00a",
      "lvl2" : "1c9ac015-9c94-d8d0-cbed-c973445af2da",
      "lvl3" : ""
    }
  },
  {
    "title": "Salé > Apéros > Croquettes",
    "subtitle": "2",
    "categories_tree": {
      "lvl0" : "42a0e188-f503-3bc6-5bf8-d78622277c4e",
      "lvl1" : "2a79ea27-c279-e471-f4d1-80b08d62b00a",
      "lvl2" : "6c4b761a-28b7-34fe-9383-1e3fb400ce87",
      "lvl3" : ""
    }
  },
  {
    "title": "Salé > Apéros > Croquettes > Croquettes de fromage",
    "subtitle": "3",
    "categories_tree": {
      "lvl0" : "42a0e188-f503-3bc6-5bf8-d78622277c4e",
      "lvl1" : "2a79ea27-c279-e471-f4d1-80b08d62b00a",
      "lvl2" : "6c4b761a-28b7-34fe-9383-1e3fb400ce87",
      "lvl3" : "757b505c-fd34-c64c-85ca-5b5690ee5293"
    }
  },
  {
    "title": "Salé > Apéros > Croquettes > Croquettes de légumes",
    "subtitle": "4",
    "categories_tree": {
      "lvl0" : "42a0e188-f503-3bc6-5bf8-d78622277c4e",
      "lvl1" : "2a79ea27-c279-e471-f4d1-80b08d62b00a",
      "lvl2" : "6c4b761a-28b7-34fe-9383-1e3fb400ce87",
      "lvl3" : "3644a684-f98e-a8fe-223c-713b77189a77"
    }
  },
  {
    "title": "Salé > Fromages",
    "subtitle": "5",
    "categories_tree": {
      "lvl0" : "42a0e188-f503-3bc6-5bf8-d78622277c4e",
      "lvl1" : "ec895663-7a99-787b-d197-eacd77acce5e",
      "lvl2" : "",
      "lvl3" : ""
    }
  },
  {
    "title": "Sucré > Autres plats sucrés > Crêpe",
    "subtitle": "6",
    "categories_tree": {
      "lvl0" : "b73ce398-c39f-506a-f761-d2277d853a92",
      "lvl1" : "31fefc0e-570c-b386-0f2a-6d4b38c6490d",
      "lvl2" : "9dcb88e0-1376-4959-0b75-5372b040afad",
      "lvl3" : ""
    }
  },
  {
    "title": "Sucré > Chocolat > Pâtisserie",
    "subtitle": "7",
    "categories_tree": {
      "lvl0" : "b73ce398-c39f-506a-f761-d2277d853a92",
      "lvl1" : "045117b0-e0a1-1a24-2b97-65e79cbf113f",
      "lvl2" : "6cdd60ea-0045-eb7a-6ec4-4c54d29ed402",
      "lvl3" : ""
    }
  },
  {
    "title": "Cocktail",
    "subtitle": "8",
    "categories_tree": {
      "lvl0" : "58a2fc6e-d39f-d083-f55d-4182bf88826d",
      "lvl1" : "",
      "lvl2" : "",
      "lvl3" : ""
    }
  }
]

*/
